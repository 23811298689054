import 'normalize.css'
import './src/styles/global.scss'
import {yandexMetrikaTrackingId} from './src/constants'

export const onRouteUpdate = ({ location }) => {
  if (
    process.env.NODE_ENV === `production` &&
    typeof window.ym !== `undefined` &&
    location
  ) {
    const { pathname, search, hash } = location;
    window.ym(yandexMetrikaTrackingId, "hit", pathname + search + hash);
  }
}
